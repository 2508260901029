<template>
    <div class="list-view">
        <div class="filters-container">
            <el-button @click="addRow" type="primary" icon="el-icon-plus" class="filter-item">添加 </el-button>
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
            <!-- <el-dropdown trigger="click" size="medium"
                         class="table-column-filter">
                <span>
                    筛选数据
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown"
                                  class="table-column-filter-wrapper">
                    <el-checkbox v-for="item in tableColumns" :key="item.value"
                                 v-model="item.show">{{item.label}}
                    </el-checkbox>
                </el-dropdown-menu>
            </el-dropdown> -->
        </div>
        <el-table :data="tableData" row-key="id" ref="table" style="margin-bottom:20px">
            <el-table-column type="index" min-width="50" align="center"> </el-table-column>
            <el-table-column v-if="isColumnShow('tableName')" prop="tableName" label="表名" min-width="100">
            </el-table-column>
            <el-table-column v-if="isColumnShow('className')" prop="className" label="类名" min-width="100">
            </el-table-column>
            <!-- <el-table-column v-if="isColumnShow('typeFlag')" prop="typeFlag"
                             label="分类" min-width="100">
            </el-table-column> -->
            <el-table-column v-if="isColumnShow('remark')" prop="remark" label="备注" min-width="100">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" min-width="150">
                <template slot-scope="scope">
                    <el-button @click="editRow(scope.row)" type="primary" size="mini" plain>编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    created() {
        this.getData();
    },
    data() {
        return {
            search: '',
            url: '/genCode/all',
            tableData: [],
            tableColumns: [
                {
                    label: '表名',
                    value: 'tableName',
                    show: true
                },
                {
                    label: '类名',
                    value: 'className',
                    show: true
                },
                {
                    label: '分类',
                    value: 'typeFlag',
                    show: true
                },
                {
                    label: '备注',
                    value: 'remark',
                    show: true
                }
            ]
        };
    },
    computed: {},
    methods: {
        getData() {
            let data = {
                searchKey: this.search
            };

            this.$http
                .get(this.url, data)
                .then(res => {
                    this.tableData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },

        addRow() {
            this.$router.push({
                path: '/genCodeEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/genCodeEdit',
                query: {
                    className: row.className
                }
            });
        },

        isColumnShow(column) {
            var row = this.tableColumns.find(i => i.value === column);
            return row ? row.show : false;
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        }
    }
};
</script>
<style lang="less" scoped></style>
